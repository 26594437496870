import Layout from '@/components/Layout';
import { AdsLazy } from '@/components/ads';
import MobileStickyAd from '@/components/ads/mobileStickyAd';
import { HOME_ADS_UNITS } from '@/constants/adsUnits';
import { HOMEPAGE_BLOCKS } from '@/constants/index';
import { getLocaleUrl } from '@/helpers/index';
import { UserAgent, UserAgentProvider } from '@quentin-sommer/react-useragent';
import { prebidAdUnits } from 'lib/prebid';
import _, { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { getLatestArticles } from 'services';
import { getAdsTypeByDevice } from '../../lib/ads';

const { TAG, CATEGORY, DESTINATIONS } = HOMEPAGE_BLOCKS;

const BannerCarousel = dynamic(() =>
  import('@/components/carousel/BannerCarousel')
);

const DestinationsCarousel = dynamic(() =>
  import('@/components/carousel/DestinationsCarousel')
);

const ArticlesCarousel = dynamic(() =>
  import('@/components/carousel/ArticlesCarousel')
);

const ArticleBlock = dynamic(() => import('@/components/article/ArticleBlock'));

const ArticleCarouselLoading = dynamic(() =>
  import('@/components/loading/ArticleCarouselLoading')
);

const defaultMetaSEO = {
  en: {
    title: 'Have Halal Will Travel - Your Global Halal Travel Guide',
    description:
      'Discover the best travel destinations, halal food guides, and Muslim-friendly travel tips. Have Halal, Will Travel is your ultimate resource for halal travel planning and adventure. Inspiring Muslims to see the world since 2015!',
  },
  id: {
    title: 'Have Halal Will Travel - Panduan Perjalanan Halal Global Anda',
    description:
      'Temukan destinasi perjalanan halal terbaik, panduan makanan halal, dan tips perjalanan ramah Muslim. Have Halal, Will Travel adalah sumber utama Anda untuk merencanakan dan menjelajahi perjalanan halal. Jelajahi dengan percaya diri dan mudah. Menginspirasi umat Islam untuk melihat dunia sejak 2015!',
  },
  ms: {
    title: 'Have Halal Will Travel - Panduan Perjalanan Halal Global Anda',
    description:
      'Temui destinasi perjalanan halal terbaik, panduan makanan halal, dan tip perjalanan mesra Muslim. Have Halal, Will Travel adalah sumber utama anda untuk merancang dan menjelajah perjalanan halal. Terokai dengan yakin dan mudah. Menginspirasi umat Islam untuk melihat dunia sejak 2015!',
  },
  ogImage:
    'https://minio.havehalalwilltravel.com/hhwt-upload/old-images/hhwt_logo.png',
  keywords:
    'Have Halal Will Travel, Recipes, Destinations, Halal Food Guides, Itinerary, Accommodations, Budget Travel, Honeymoon, Beach Holidays',
};

function Homepage({ bannerData, bodyData = [] }) {
  const router = useRouter();
  const { locale } = router;

  const [favData, setFavData] = useState(null);
  const [bucketList, setBucketList] = useState([]);
  const [latestArticles, setLatestArticles] = useState([]);

  const favClick = (post) => {
    if (!isEmpty(post)) {
      setFavData(post);
      window.jQuery('#addbucket-modal').modal('show');
      window.jQuery('.hideNew').hide();
    }
  };

  const renderAdsContent = {
    1: (
      <UserAgentProvider
        ua={typeof window !== 'undefined' ? window.navigator.userAgent : ''}
      >
        <>
          <UserAgent android ios>
            <center
              style={{
                marginLeft: '-14px',
              }}
            >
              <p
                id="mobile_homepage_slot1"
                style={{
                  display: 'none',
                  fontSize: 10,
                }}
              >
                Advertisement
              </p>
              <AdsLazy
                path={HOME_ADS_UNITS.Mobile_AdSlot_1.path}
                id={HOME_ADS_UNITS.Mobile_AdSlot_1.id}
                dimensions={HOME_ADS_UNITS.Mobile_AdSlot_1.dimensions}
                isCollapseEmptyDivs={
                  HOME_ADS_UNITS.Mobile_AdSlot_1.isCollapseEmptyDivs
                }
                onSlotRenderEnded={(e) => {
                  if (e.isEmpty === false) {
                    jQuery('#mobile_homepage_slot1_lt').css('display', 'block');
                    jQuery('#mobile_homepage_slot1').css('display', 'block');
                    jQuery('#mobile_homepage_slot1_lb').css('display', 'block');
                  } else {
                    jQuery('#mobile_homepage_slot1_lt').css('display', 'none');
                    jQuery('#mobile_homepage_slot1').css('display', 'none');
                    jQuery('#mobile_homepage_slot1_lb').css('display', 'none');
                  }
                }}
              />
            </center>
          </UserAgent>
          <UserAgent windows mac linux>
            <center
              style={{
                marginBottom: '0px',
              }}
            >
              <p
                id="desktop_midpage_adslot1"
                style={{
                  display: 'none',
                  fontSize: 12,
                }}
              >
                Advertisement
              </p>
              <AdsLazy
                path={HOME_ADS_UNITS.Desktop_Homepage_AdSlot_1.path}
                id="desktop-homepage-ad-1"
                dimensions={HOME_ADS_UNITS.Desktop_Homepage_AdSlot_1.dimensions}
                isCollapseEmptyDivs={
                  HOME_ADS_UNITS.Desktop_Homepage_AdSlot_1.isCollapseEmptyDivs
                }
                onSlotRenderEnded={(e) => {
                  if (e.isEmpty === false) {
                    jQuery('#desktop_midpage_adslot1_lt').css(
                      'display',
                      'block'
                    );
                    jQuery('#desktop_midpage_adslot1').css('display', 'block');
                    jQuery('#desktop_midpage_adslot1_lb').css(
                      'display',
                      'block'
                    );
                  } else {
                    jQuery('#desktop_midpage_adslot1_lt').css(
                      'display',
                      'none'
                    );
                    jQuery('#desktop_midpage_adslot1').css('display', 'none');
                    jQuery('#desktop_midpage_adslot1_lb').css(
                      'display',
                      'none'
                    );
                  }
                }}
              />
            </center>
          </UserAgent>
        </>
      </UserAgentProvider>
    ),
    2: (
      <UserAgentProvider
        ua={typeof window !== 'undefined' ? window.navigator.userAgent : ''}
      >
        <>
          <UserAgent android ios>
            <center
              style={{
                marginLeft: '-14px',
              }}
            >
              <p
                id="mobile_homepage_slot2"
                style={{
                  display: 'none',
                  fontSize: 10,
                }}
              >
                Advertisement
              </p>
              <AdsLazy
                path={HOME_ADS_UNITS.Mobile_AdSlot_2.path}
                id={HOME_ADS_UNITS.Mobile_AdSlot_2.id}
                dimensions={HOME_ADS_UNITS.Mobile_AdSlot_2.dimensions}
                isCollapseEmptyDivs={
                  HOME_ADS_UNITS.Mobile_AdSlot_2.isCollapseEmptyDivs
                }
                onSlotRenderEnded={(e) => {
                  if (e.isEmpty === false) {
                    jQuery('#mobile_homepage_slot2_lt').css('display', 'block');
                    jQuery('#mobile_homepage_slot2').css('display', 'block');
                    jQuery('#mobile_homepage_slot2_lb').css('display', 'block');
                  } else {
                    jQuery('#mobile_homepage_slot2_lt').css('display', 'none');
                    jQuery('#mobile_homepage_slot2').css('display', 'none');
                    jQuery('#mobile_homepage_slot2_lb').css('display', 'none');
                  }
                }}
              />
            </center>
          </UserAgent>
          <UserAgent windows mac linux>
            <center
              style={{
                marginBottom: '0px',
              }}
            >
              <p
                id="desktop_midpage_adslot2"
                style={{
                  display: 'none',
                  fontSize: 12,
                }}
              >
                Advertisement
              </p>
              <AdsLazy
                path={HOME_ADS_UNITS.Desktop_Homepage_AdSlot_2.path}
                id={HOME_ADS_UNITS.Desktop_Homepage_AdSlot_2.id}
                dimensions={HOME_ADS_UNITS.Desktop_Homepage_AdSlot_2.dimensions}
                isCollapseEmptyDivs={
                  HOME_ADS_UNITS.Desktop_Homepage_AdSlot_2.isCollapseEmptyDivs
                }
                onSlotRenderEnded={(e) => {
                  if (e.isEmpty === false) {
                    jQuery('#desktop_midpage_adslot2_lt').css(
                      'display',
                      'block'
                    );
                    jQuery('#desktop_midpage_adslot2').css('display', 'block');
                    jQuery('#desktop_midpage_adslot2_lb').css(
                      'display',
                      'block'
                    );
                  } else {
                    jQuery('#desktop_midpage_adslot2_lt').css(
                      'display',
                      'none'
                    );
                    jQuery('#desktop_midpage_adslot2').css('display', 'none');
                    jQuery('#desktop_midpage_adslot2_lb').css(
                      'display',
                      'none'
                    );
                  }
                }}
              />
            </center>
          </UserAgent>
        </>
      </UserAgentProvider>
    ),
    3: (
      <UserAgentProvider
        ua={typeof window !== 'undefined' ? window.navigator.userAgent : ''}
      >
        <>
          <UserAgent android ios>
            <center
              style={{
                marginLeft: '-14px',
              }}
            >
              <p
                id={`mobile_homepage_slot4`}
                style={{
                  display: 'none',
                  fontSize: 10,
                }}
              >
                Advertisement
              </p>
              <AdsLazy
                path={HOME_ADS_UNITS.Mobile_AdSlot_3.path}
                id={HOME_ADS_UNITS.Mobile_AdSlot_3.id}
                dimensions={HOME_ADS_UNITS.Mobile_AdSlot_3.dimensions}
                isCollapseEmptyDivs={
                  HOME_ADS_UNITS.Mobile_AdSlot_3.isCollapseEmptyDivs
                }
                onSlotRenderEnded={(e) => {
                  if (e.isEmpty === false) {
                    jQuery('#mobile_homepage_slot4_lt').css('display', 'block');
                    jQuery('#mobile_homepage_slot4').css('display', 'block');
                    jQuery('#mobile_homepage_slot4_lb').css('display', 'block');
                  } else {
                    jQuery('#mobile_homepage_slot4_lt').css('display', 'none');
                    jQuery('#mobile_homepage_slot4').css('display', 'none');
                    jQuery('#mobile_homepage_slot4_lb').css('display', 'none');
                  }
                }}
              />
            </center>
          </UserAgent>
          <UserAgent windows mac linux>
            <center
              style={{
                marginBottom: '0px',
              }}
            >
              <p
                id={`desktop_midpage_adslot4`}
                style={{
                  display: 'none',
                  fontSize: 12,
                }}
              >
                Advertisement
              </p>
              <AdsLazy
                path={HOME_ADS_UNITS.Desktop_Homepage_AdSlot_3.path}
                id={HOME_ADS_UNITS.Desktop_Homepage_AdSlot_3.id}
                dimensions={HOME_ADS_UNITS.Desktop_Homepage_AdSlot_3.dimensions}
                isCollapseEmptyDivs={
                  HOME_ADS_UNITS.Desktop_Homepage_AdSlot_3.isCollapseEmptyDivs
                }
                onSlotRenderEnded={(e) => {
                  if (e.isEmpty === false) {
                    jQuery('#desktop_midpage_adslot4_lt').css(
                      'display',
                      'block'
                    );
                    jQuery('#desktop_midpage_adslot4').css('display', 'block');
                    jQuery('#desktop_midpage_adslot4_lb').css(
                      'display',
                      'block'
                    );
                  } else {
                    jQuery('#desktop_midpage_adslot4_lt').css(
                      'display',
                      'none'
                    );
                    jQuery('#desktop_midpage_adslot4').css('display', 'none');
                    jQuery('#desktop_midpage_adslot4_lb').css(
                      'display',
                      'none'
                    );
                  }
                }}
              />
            </center>
          </UserAgent>
        </>
      </UserAgentProvider>
    ),
  };

  const handleRenderHomepageContent = useCallback(() => {
    const contentRendered = _.map(bodyData, (item, index) => {
      const itemType = get(item, 'type');
      switch (itemType) {
        case TAG:
        case CATEGORY:
          const title = get(
            item,
            `value.${itemType}.${itemType === CATEGORY ? 'value' : 'label'}`
          );
          const formatTitle = title
            .replace(/-/g, ' ')
            .replace(/\b\w/g, (match) => match.toUpperCase());

          return (
            <React.Fragment key={index}>
              <ArticleBlock
                position={index + 1}
                isGetByCategory={Boolean(get(item, 'type') === CATEGORY)}
                name={title}
                mainTitle={formatTitle}
                locale={locale}
                onAddFavourite={favClick}
              />

              {renderAdsContent[(index + 1) / 2] || null}
            </React.Fragment>
          );

        case DESTINATIONS:
          return (
            <section key={index} className="destinations-section">
              <DestinationsCarousel
                dataDestination={get(item, 'value')}
                locale={locale}
              />
            </section>
          );

        default:
          return <div />;
      }
    });

    return contentRendered;
  }, [bodyData, locale]);

  useEffect(() => {
    const adsTypeToBid = getAdsTypeByDevice();

    prebidAdUnits(
      Object.values(HOME_ADS_UNITS).filter((adUnit) =>
        adsTypeToBid.some((adType) => adType === adUnit.type)
      )
    );
  }, []);

  useEffect(() => {
    const getInitialLatestArticles = async () => {
      try {
        const response = await getLatestArticles(locale);
        setLatestArticles(get(response, 'data.articles', []));
      } catch (error) {
        console.log(error);
      }
    };

    getInitialLatestArticles();
  }, []);

  const homeSEO = defaultMetaSEO[locale] || defaultMetaSEO['en'];

  return (
    <Layout
      title={homeSEO.title}
      description={homeSEO.description}
      og_title={homeSEO.title}
      og_description={homeSEO.description}
    >
      <Head>
        <script
          defer
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
              {
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "${getLocaleUrl(locale, '')}",
              "logo": "https://minio.havehalalwilltravel.com/hhwt-upload/old-images/hhwt_logo.png",
              "sameAs":
              [
              "https://www.facebook.com/havehalalwilltravel",
              "https://twitter.com/tweethhwt",
              "https://www.instagram.com/havehalalwilltravel"
              ]
            }
            `,
          }}
        />
        <link rel="canonical" href={getLocaleUrl(locale, '')} />
      </Head>
      {/* <!-- MainWrapper Starts here --> */}

      {/* {isLoading && <Loading />} */}

      <div className="homepage">
        {get(bannerData, 'header.length', 0) > 0 && (
          <section className="banner-section">
            <BannerCarousel banner={bannerData.header} />
          </section>
        )}

        <UserAgentProvider
          ua={typeof window !== 'undefined' ? window.navigator.userAgent : ''}
        >
          <>
            <UserAgent android ios>
              <center
                style={{
                  marginLeft: '-14px',
                  marginTop: '15px',
                }}
              >
                <p
                  id="mobile_homepage_atf"
                  style={{
                    display: 'none',
                    fontSize: 10,
                  }}
                >
                  Advertisement
                </p>
                <AdsLazy
                  path={HOME_ADS_UNITS.Mobile_ATF_New.path}
                  id={HOME_ADS_UNITS.Mobile_ATF_New.id}
                  dimensions={HOME_ADS_UNITS.Mobile_ATF_New.dimensions}
                  isCollapseEmptyDivs={
                    HOME_ADS_UNITS.Mobile_ATF_New.isCollapseEmptyDivs
                  }
                  onSlotRenderEnded={(e) => {
                    if (e.isEmpty === false) {
                      jQuery('#mobile_homepage_atf_lt').css('display', 'block');
                      jQuery('#mobile_homepage_atf').css('display', 'block');
                      jQuery('#mobile_homepage_atf_lb').css('display', 'block');
                    } else {
                      jQuery('#mobile_homepage_atf_lt').css('display', 'none');
                      jQuery('#mobile_homepage_atf').css('display', 'none');
                      jQuery('#mobile_homepage_atf_lb').css('display', 'none');
                    }
                  }}
                />
              </center>
            </UserAgent>

            <UserAgent windows mac linux>
              <center
                style={{
                  marginBottom: '0px',
                  marginTop: '15px',
                }}
              >
                <p
                  id="desktop_articlepage_atf"
                  style={{
                    fontSize: 12,
                    display: 'none',
                  }}
                >
                  Advertisement
                </p>
                <AdsLazy
                  path={HOME_ADS_UNITS.Desktop_HomePage_ATF.path}
                  id={HOME_ADS_UNITS.Desktop_HomePage_ATF.id}
                  dimensions={HOME_ADS_UNITS.Desktop_HomePage_ATF.dimensions}
                  isCollapseEmptyDivs={
                    HOME_ADS_UNITS.Desktop_HomePage_ATF.isCollapseEmptyDivs
                  }
                  onSlotRenderEnded={(e) => {
                    if (e.isEmpty === false) {
                      jQuery('#desktop_articlepage_atf_lt').css(
                        'display',
                        'block'
                      );
                      jQuery('#desktop_articlepage_atf').css(
                        'display',
                        'block'
                      );
                      jQuery('#desktop_articlepage_atf_lb').css(
                        'display',
                        'block'
                      );
                    } else {
                      jQuery('#desktop_articlepage_atf_lt').css(
                        'display',
                        'none'
                      );
                      jQuery('#desktop_articlepage_atf').css('display', 'none');
                      jQuery('#desktop_articlepage_atf_lb').css(
                        'display',
                        'none'
                      );
                    }
                  }}
                />
              </center>
            </UserAgent>
          </>
        </UserAgentProvider>

        <section className="latest-articles articles-section">
          <ArticlesCarousel
            name="Latest Articles"
            viewAllSlug=""
            articles={latestArticles}
            onAddFavourite={favClick}
          />

          {latestArticles.length === 0 && (
            <div style={{ paddingBottom: 32 }}>
              <ArticleCarouselLoading isLatestArticles />
            </div>
          )}
        </section>

        {handleRenderHomepageContent()}
      </div>
      <UserAgentProvider
        ua={typeof window !== 'undefined' ? window.navigator.userAgent : ''}
      >
        <>
          <UserAgent android ios>
            <center
              style={{
                marginLeft: '14px',
              }}
            >
              <p
                id="mobile_homepage_btf"
                style={{
                  display: 'none',
                  fontSize: 10,
                }}
              >
                Advertisement
              </p>
              <AdsLazy
                path={HOME_ADS_UNITS.Mobile_BTF.path}
                id={HOME_ADS_UNITS.Mobile_BTF.id}
                dimensions={HOME_ADS_UNITS.Mobile_BTF.dimensions}
                isCollapseEmptyDivs={
                  HOME_ADS_UNITS.Mobile_BTF.isCollapseEmptyDivs
                }
                onSlotRenderEnded={(e) => {
                  if (e.isEmpty === false) {
                    jQuery('#mobile_homepage_btf_lt').css('display', 'block');
                    jQuery('#mobile_homepage_btf').css('display', 'block');
                    jQuery('#mobile_homepage_btf_lb').css('display', 'block');
                  } else {
                    jQuery('#mobile_homepage_btf_lt').css('display', 'none');
                    jQuery('#mobile_homepage_btf').css('display', 'none');
                    jQuery('#mobile_homepage_btf_lb').css('display', 'none');
                  }
                }}
              />
            </center>
          </UserAgent>

          <UserAgent windows mac linux>
            <center
              style={{
                marginBottom: '0px',
              }}
            >
              <p
                id="desktop_homepage_btf"
                style={{
                  display: 'none',
                  fontSize: 12,
                }}
              >
                Advertisement
              </p>
              <AdsLazy
                path={HOME_ADS_UNITS.Desktop_HomePage_BTF.path}
                id={HOME_ADS_UNITS.Desktop_HomePage_BTF.id}
                dimensions={HOME_ADS_UNITS.Desktop_HomePage_BTF.dimensions}
                isCollapseEmptyDivs={
                  HOME_ADS_UNITS.Desktop_HomePage_BTF.isCollapseEmptyDivs
                }
                onSlotRenderEnded={(e) => {
                  if (e.isEmpty === false) {
                    jQuery('#desktop_homepage_btf_lt').css('display', 'block');
                    jQuery('#desktop_homepage_btf').css('display', 'block');
                    jQuery('#desktop_homepage_btf_lb').css('display', 'block');
                  } else {
                    jQuery('#desktop_homepage_btf_lt').css('display', 'none');
                    jQuery('#desktop_homepage_btf').css('display', 'none');
                    jQuery('#desktop_homepage_btf_lb').css('display', 'none');
                  }
                }}
              />
            </center>
          </UserAgent>
        </>
      </UserAgentProvider>

      <UserAgentProvider
        ua={typeof window !== 'undefined' ? window.navigator.userAgent : ''}
      >
        <UserAgent android ios>
          <MobileStickyAd />
        </UserAgent>
      </UserAgentProvider>
    </Layout>
  );
}

export default Homepage;
