import _ from 'lodash';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';

const Footer = dynamic(() => import('./footer'));
const Header = dynamic(() => import('./header'));

export default function Layout({
  title,
  keywords,
  description,
  children,
  og_title,
  og_description,
  og_image,
  isBotCrawler,
  allowLocales = [],
  acceptLocale = 'en',
  isNoIndex = false,
}) {
  const router = useRouter();
  const { locales } = router;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content={og_title}></meta>
        <meta property="og:description" content={og_description}></meta>
        <meta property="og:image" content={og_image}></meta>
        <meta property="og:image:width" content="1200"></meta>
        <meta property="og:image:height" content="630"></meta>
        <meta property="og:image:type" content="image/jpeg"></meta>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={og_title} />
        <meta name="twitter:site" content="@tweethhwt" />
        <meta name="twitter:description" content={og_description} />
        <meta name="twitter:image" content={og_image} />
        <meta name="cf-2fa-verify" content="M2kCJ43UvBJLnOnbmv0R"></meta>
        <meta
          httpEquiv="Permissions-Policy"
          content="interest-cohort=()"
        ></meta>
        {isNoIndex && (
          <>
            <meta name="robots" content="noindex"></meta>
            <meta name="googlebot" content="noindex"></meta>
          </>
        )}

        <link
          rel="alternate"
          href={`${process.env.NEXT_PUBLIC_LANDING_URL}${
            acceptLocale === 'en' ? '' : `/${acceptLocale}`
          }${router.asPath === '/' ? '' : router.asPath}`}
          hrefLang="x-default"
        />
        {_.map(allowLocales?.length > 0 ? allowLocales : locales, (locale) => (
          <link
            key={locale}
            rel="alternate"
            href={`${process.env.NEXT_PUBLIC_LANDING_URL}${
              locale === 'en' ? '' : `/${locale}`
            }${router.asPath === '/' ? '' : router.asPath}`}
            hrefLang={locale}
          />
        ))}
      </Head>

      <Header isBotCrawler={isBotCrawler} allowLocales={allowLocales} />

      <div>{children}</div>

      <Footer />
    </>
  );
}

Layout.defaultProps = {
  title: 'Have Halal Will Travel - Your Global Halal Travel Guide',
  description:
    'Discover the best travel destinations, halal food guides, and Muslim-friendly travel tips. Have Halal, Will Travel is your ultimate resource for halal travel planning and adventure. Inspiring Muslims to see the world since 2015!',
  keywords:
    'Have Halal Will Travel, Recipes, Destinations, Halal Food Guides, Itinerary, Accommodations, Budget Travel, Honeymoon, Beach Holidays',
  og_title: 'Have Halal Will Travel - Your Global Halal Travel Guide',
  og_description:
    'Discover the best travel destinations, halal food guides, and Muslim-friendly travel tips. Have Halal, Will Travel is your ultimate resource for halal travel planning and adventure. Inspiring Muslims to see the world since 2015!',
  og_image:
    'https://minio.havehalalwilltravel.com/hhwt-upload/old-images/hhwt_logo.png',
};
